import {
  getAllPayrollService,
  updateApprovedHoursService,
  updateReOpenHoursService,
  updateRejectedHoursService
} from 'Services/Payroll'
import { createQueryString } from 'Utils/helperFunctions'
import { useState } from 'react'

export default function usePayroll () {
  const [loadingPayrollHook, setLoadingPayrollHook] = useState(false)
  const [payrollHook, setPayrollHook] = useState([])

  const getAllPayroll = async ({ startDate, endDate, user_id, is_approved, project_ids, users_ids }) => {
    try {
      setLoadingPayrollHook(true)
      const query = createQueryString({
        date: startDate && endDate ? `${startDate}:${endDate}` : '',
        is_approved,
        user_id,
        project_ids,
        users_ids
      })
      const payroll = await getAllPayrollService({ query })
      setPayrollHook(payroll?.data || [])
      return payroll?.data || []
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingPayrollHook(false)
    }
  }

  const handleUpdateApprovedHoursHook = async ({ startDate, endDate, user_id, project_ids }) => {
    try {
      setLoadingPayrollHook(true)
      await updateApprovedHoursService({
        body: {
          start_date: startDate,
          end_date: endDate,
          user_id,
          project_ids
        }
      })
      return { success: true }
    } catch (e) {
      return { success: false }
    } finally {
      setLoadingPayrollHook(false)
    }
  }

  const handleUpdateReOpenHoursHook = async ({ startDate, endDate, user_id, project_ids, note }) => {
    try {
      setLoadingPayrollHook(true)
      await updateReOpenHoursService({
        body: {
          start_date: startDate,
          end_date: endDate,
          user_id,
          project_ids,
          note
        }
      })
      return { success: true }
    } catch (e) {
      return { success: false }
    } finally {
      setLoadingPayrollHook(false)
    }
  }

  const handleUpdateRejectHoursHook = async ({ startDate, endDate, user_id, project_ids, comment }) => {
    try {
      setLoadingPayrollHook(true)
      await updateRejectedHoursService({
        body: {
          start_date: startDate,
          end_date: endDate,
          user_id,
          project_ids,
          comment
        }
      })
      return { success: true }
    } catch (e) {
      return { success: false }
    } finally {
      setLoadingPayrollHook(false)
    }
  }

  return {
    loadingPayrollHook,
    payrollHook,
    getAllPayroll,
    handleUpdateApprovedHoursHook,
    handleUpdateReOpenHoursHook,
    handleUpdateRejectHoursHook
  }
}
