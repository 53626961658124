import useSessionStore from '../../../Context/useSessionStore'
import { useFormContext, useFieldArray, useWatch, Controller } from 'react-hook-form'
import CurrencyInput from 'react-currency-input-field'
import { Plus, TrashSimple } from '@phosphor-icons/react'
import { tzStrings } from 'Utils/helperFunctions'
import {
  ROLES_DISPLAY,
  ROLE_ADMIN_NAME,
  ROLE_DEVELOPER_NAME,
  ROLE_OPERATION_MANAGER_NAME,
  ROLE_PRODUCT_OWNER_NAME
} from 'constant/roles'
import { COLOR_BLACK, COLOR_BLUE_MATE } from 'constant/colors'
import { PERMISSION_BY_ROLE } from 'Utils/permissionByRol'

const BasicInfo = ({ selectedUser, onChangeRole, onRemoveResource }) => {
  const { user } = useSessionStore((state) => ({ user: state.user }))

  const {
    control,
    register,
    formState: { errors },
    setValue
  } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'resources'
  })

  const capacityOptions = []
  for (let i = 1; i <= 120; i++) {
    capacityOptions.push(i / 2)
  }

  const is_hourly_based = useWatch({
    control,
    name: 'is_hourly_based',
    defaultValue: 0
  })

  const costRate = useWatch({
    control,
    name: 'cost_rates',
    defaultValue: 0
  })

  const capacity = useWatch({
    control,
    name: 'capacity',
    defaultValue: 40
  })

  const handleChangeRole = (e) => {
    setValue('role', e.target.value)
    onChangeRole({ role: e.target.value })
  }

  const handleRemoveResource = async ({ index }) => {
    if (fields[index]?.id_resource) {
      await onRemoveResource({ id: fields[index]?.id_resource })
    }

    remove(index)
  }

  return (
    <div className="row justify-content-center">
      <div className="col-11 d-grid">
        <div className="row mb-3">
          <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputFirstName">
            First Name *
          </label>
          <div className="col-lg-10 col-md-9 col-12">
            <input
              {...register('firstname', { required: true })}
              type="text"
              className={`form-control ${errors && errors.firstname ? 'is-invalid' : ''}`}
              id="inputFirstName"
              placeholder="First name"
            />
            <div className="invalid-feedback">Please provide a First name.</div>
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputLastName">
            Last Name *
          </label>
          <div className="col-lg-10 col-md-9 col-12">
            <input
              name="lastname"
              type="text"
              {...register('lastname', { required: true })}
              className={`form-control ${errors && errors.lastname ? 'is-invalid' : ''}`}
              id="inputLastName"
              placeholder="Last name"
            />
            <div className="invalid-feedback">Please provide a Last name.</div>
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputEmail">
            Email *
          </label>
          <div className="col-lg-10 col-md-9 col-12">
            <input
              name="email"
              autoComplete="false"
              type="email"
              {...register('email', { required: true })}
              className={`form-control ${errors?.email ? 'is-invalid' : ''}`}
              id="inputEmail"
              placeholder="Email"
              disabled={selectedUser}
            />
            <div className="invalid-feedback">
              {errors?.email?.type === 'exists' ? errors.email.message : 'Please provide an Email.'}
            </div>
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputSecondaryEmail">
            Secondary Email
          </label>
          <div className="col-lg-10 col-md-9 col-12">
            <input
              {...register('secondary_email')}
              type="text"
              className={`form-control ${errors && errors.secondary_email ? 'is-invalid' : ''}`}
              id="inputSecondaryEmail"
              placeholder="Secondary email"
            />
          </div>
        </div>
        <hr />
        <div className="row mb-3">
          <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputRole">
            Role *
          </label>
          <div className="col-lg-10 col-md-9 col-12">
            <select
              id="inputRole"
              className="form-select"
              {...register('role', { required: true })}
              onChange={handleChangeRole}>
              <option value={ROLE_DEVELOPER_NAME}>{ROLES_DISPLAY[ROLE_DEVELOPER_NAME]}</option>
              <option value={ROLE_PRODUCT_OWNER_NAME}>{ROLES_DISPLAY[ROLE_PRODUCT_OWNER_NAME]}</option>
              <option value={ROLE_OPERATION_MANAGER_NAME}>{ROLES_DISPLAY[ROLE_OPERATION_MANAGER_NAME]}</option>
              {user.role === ROLE_ADMIN_NAME && (
                <option value={ROLE_ADMIN_NAME}>{ROLES_DISPLAY[ROLE_ADMIN_NAME]}</option>
              )}
            </select>
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputPosition">
            Position
          </label>
          <div className="col-lg-10 col-md-9 col-12">
            <input
              {...register('position')}
              type="text"
              className={`form-control ${errors && errors.position ? 'is-invalid' : ''}`}
              id="inputPosition"
              placeholder="Frontend Developer / Fullstack Developer / Designer / QA"
            />
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputCapacity">
            Capacity *
          </label>
          <div className="col-lg-10 col-md-9 col-12">
            <select
              className="form-select"
              id="inputCapacity"
              defaultValue={capacity}
              {...register('capacity', { required: true })}>
              {capacityOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="col-auto">
            <p className="mb-0">hours per week</p>
          </div>
        </div>
        <hr />

        {PERMISSION_BY_ROLE.USER.EDIT.SHOW_COST_BY_USER.includes(user.role) && (
          <>
            <div className="row">
              <div className="col-2">
                <p className={`${is_hourly_based ? '' : 'text-active'}`}>Fixed</p>
              </div>
              <div className="col-10 d-flex">
                <div className="col-auto form-check form-switch mb-3">
                  <input
                    id="is_hourly_based"
                    className="form-check-input"
                    type="checkbox"
                    role="switch switch_both"
                    {...register('is_hourly_based')}
                  />
                </div>
                <div className="col-auto ms-3">
                  <p className={`${is_hourly_based ? 'text-active' : ''}`}>Hourly based</p>
                </div>
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputLastName">
                {!is_hourly_based ? 'Monthly' : ''} Cost rate
              </label>
              {!is_hourly_based
                ? (
                <div className="col-lg-10 col-md-9 col-12 d-flex align-items-center">
                  <div style={{ maxWidth: 100 }}>
                    <Controller
                      control={control}
                      {...register('cost_rates')}
                      render={({ field: { onChange, name } }) => (
                        <CurrencyInput
                          name={name}
                          id="costRates"
                          onBlur={(e) => {
                            if (e.target.value) return
                            setValue('cost_rates', 0)
                          }}
                          onValueChange={(value) => onChange(value || '')}
                          defaultValue={costRate}
                          prefix="$"
                          value={costRate ?? ''}
                          // decimalSeparator="."
                          // thousandseparator=","
                          precision={2}
                          className={`form-control ${errors && errors.cost_rates ? 'is-invalid' : ''}`}
                          decimalsLimit={2}
                          placeholder="$0.00"
                        />
                      )}
                    />
                  </div>

                  <p className="mb-0 me-3 ms-3">/</p>

                  <div style={{ maxWidth: 100 }}>
                    <Controller
                      control={control}
                      {...register('cost_rates')}
                      render={({ field: { onChange, onBlur, name, value } }) => (
                        <CurrencyInput
                          name={name}
                          onBlur={onBlur}
                          onValueChange={(value) => onChange(value)}
                          disabled
                          prefix="$"
                          id="costRatePerHour"
                          decimalSeparator="."
                          thousandseparator=","
                          precision={2}
                          decimalsLimit={2}
                          className={`form-control ${errors.cost_rates ? 'is-invalid' : ''}`}
                          placeholder="$0.00"
                          value={costRate / (capacity * 4)}
                        />
                      )}
                    />
                  </div>

                  <p className="mb-0 ms-3">
                    per hour <span>aprox.</span>
                  </p>
                </div>
                  )
                : (
                <div className="col-lg-10 col-md-9 col-12 d-flex align-items-center">
                  <div style={{ maxWidth: 100 }}>
                    <Controller
                      control={control}
                      {...register('cost_rates')}
                      render={({ field: { onChange, name } }) => (
                        <CurrencyInput
                          name={name}
                          id="costRates"
                          onBlur={(e) => {
                            if (e.target.value) return
                            setValue('cost_rates', 0)
                          }}
                          onValueChange={(value) => onChange(value || '')}
                          defaultValue={costRate}
                          prefix="$"
                          value={costRate ?? ''}
                          decimalSeparator="."
                          thousandseparator=","
                          precision={2}
                          className={`form-control ${errors && errors.cost_rates ? 'is-invalid' : ''}`}
                          decimalsLimit={2}
                          placeholder="$0.00"
                        />
                      )}
                    />
                  </div>
                  <p className="mb-0 ms-3">per hour</p>
                </div>
                  )}
            </div>
            <hr />
          </>
        )}

        <div className="row align-items-center mb-3">
          <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputTimezone">
            Timezone
          </label>
          <div className="col-lg-10 col-md-9 col-12">
            <select id="inputTimezone" className="form-select" {...register('timezone')}>
              {tzStrings.map((item, key) => (
                <option key={key} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row align-items-center mb-3">
          <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputNationality">
            Nationality
          </label>
          <div className="col-lg-10 col-md-9 col-12">
            <select id="inputNationality" className="form-select" {...register('nationality')}>
              <option value="Argentina">Argentina</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Colombia">Colombia</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Mexico">Mexico</option>
              <option value="Panama">Panama</option>
              <option value="Venezuela">Venezuela</option>
            </select>
          </div>
        </div>

        <div className="row align-items-center mb-3">
          <label className="col-lg-2 col-md-3 col-12 col-form-label" htmlFor="inputLocation">
            Location
          </label>
          <div className="col-lg-10 col-md-9 col-12">
            <input
              name="location"
              type="text"
              {...register('location')}
              className={`form-control ${errors && errors.location ? 'is-invalid' : ''}`}
              id="inputLocation"
              placeholder="Location"
            />
          </div>
        </div>
        <hr />
        <div className="row mb-1">
          <h5 htmlFor="inputResources">Resources</h5>
        </div>
        {fields.length > 0 &&
          fields.map((field, index) => (
            <div key={field.id} className="row mb-3">
              <label
                className="col-sm-2 col-form-label"
                htmlFor={`inputResource-${index}`}
                data-cypress-items="resource">
                Resource {index + 1}
              </label>
              <div className="col">
                <input
                  type="text"
                  {...register(`resources.${index}.value`)}
                  id={`inputResource-${index}`}
                  className={`form-control ${errors?.resources?.[index]?.value ? 'is-invalid' : ''}`}
                  placeholder="https://"
                />
              </div>
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-secondary rounded"
                  onClick={() => handleRemoveResource({ index })}>
                  <TrashSimple size={18} color={COLOR_BLUE_MATE} weight="duotone" />
                </button>
              </div>
            </div>
          ))}
        <div className="row mb-5">
          <div className="col-auto">
            <button type="button" onClick={() => append({ value: '' })} className="btn btn-secondary">
              <Plus className="icon" size={15} color={COLOR_BLACK} weight="bold" /> Add resource
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicInfo
